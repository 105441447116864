
import http from "../../../../../common/services/http";
import { computed, defineComponent, ref, reactive, onMounted } from "vue";
import moment from "moment";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Agent",
      created_at: "Join Date",
      email: "Email Address",
      phone: "Phone Number"
    };

    const currencies = ref([
      { id: "euro", title: "Euro" },
      { id: "pound", title: "Sterling Pound" },
      { id: "shilling", title: "Kenyan Shilling" },
      { id: "dollar", title: "US Dollar" }
    ]);

    const agents = ref({
      data: [],
      first_page: 1,
      last_page: 1
    });

    const agent = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "agent", title: "Agent" },
      contact: {
        currency: "pound",
        address_1: "Address"
      }
    });

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchAgents(page = 1) {
      processing.value = true;
      http.get(`/api/users?role=agent&page=${page}`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function searchAgents(e: any) {
      processing.value = true;
      http.get(`/api/users?role=agent&s=${search.value}`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function createAgent() {
      http
        .post("/api/users", agent)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          agent.first_name = "";
          agent.last_name = "";
          agent.email = "";
          agent.phone = "";
          agent.role = { id: "agent", title: "Agent" };
          agent.contact = {
            currency: "pound",
            address_1: "Address"
          };
          document.getElementById("hideNewForm")?.click();
          fetchAgents();
        });
    }

    function updateAgent(agent: any) {
      agent._method = "PUT";
      http
        .post(`/api/users/${agent.id}`, agent)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchAgents();
        });
    }

    function deleteAgent(id: any) {
      http.post(`/api/users/${id}`, { _method: "DELETE" }).then(res => {
        fetchAgents();
      });
    }

    function formatDate(date: moment.MomentInput) {
      return moment(date).format("dddd, MMM Do, yyyy");
    }

    onMounted(() => {
      fetchAgents();
    });

    return {
      headers,
      fetchAgents,
      processing,
      agents,
      createAgent,
      created,
      agent,
      updateAgent,
      updated,
      deleteAgent,
      search,
      searchAgents,
      currencies,
      formatDate
    };
  }
});
